import React, { Component } from "react";
import { Divider } from 'antd';
import "./App.scss";

class BlogPage extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Teaching Portfolio </h1>
        <ol reversed>
        <li className="pubList">
            <h2 className="publicationTitle">
              <a href="/blog241212"> MIT Kaufman Teaching Certificate Program </a> </h2>
            <p className="journalText"> Sep - Dec 2024</p>
          </li>
          <Divider />
        <li className="pubList">
            <h2 className="publicationTitle">
              <a href="/blog240318"> Extreme engineering (MIT Spark 2024) </a> </h2>
            <p className="journalText"> March 2024</p>
          </li>
          <Divider />
        <li className="pubList">
            <h2 className="publicationTitle">
              <a href="/blog230716"> Industrial decarbonization (MIT HSSP and Splash 2023) </a> </h2>
            <p className="journalText"> July 2023</p>
          </li>
          <Divider />
          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="/blog230320"> Atomistic science (MIT Spark 2023) </a> </h2>
            <p className="journalText"> March 2023</p>
          </li>
          <Divider />
          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="/blog190500"> Statics and mechanics (Duke EGR 201) </a> </h2>
            <p className="journalText"> Aug 2016 - May 2019</p>
          </li>
          <Divider />
        </ol>
      </div>
    );
  }
}
export default BlogPage;
