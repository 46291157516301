import React, { Component } from "react";
// import { Divider } from 'antd';
import "../App.scss";

class KTCP_241212 extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Kaufmann Teaching Certificate Program </h1>
        <h3> December 15, 2024 </h3>
        <p>
                This semester I had the pleasure of participating in the <a href="https://tll.mit.edu/programming/grad-student-programming/kaufman-teaching-certificate-program/" target="_blank">Kaufmann Teaching Certificate Program</a> (KTCP) at MIT. This program is designed to help graduate students and postdocs develop their teaching skills and philosophies. The program consists of 6 workshops, each of which focuses on a different aspect of teaching, as well as two micro-teaching sessions where we get to put theory into practice in quick 10-minute lessons.
            </p>
            <p> 
                Over the course of the semester I developed a learner-focused course on radiative heat transfer, employing techniques like backwards design to start with the learning objectives and work backwards to the content. I also learned some techniques for creating an inclusive classroom environment, ensuring student engagement through active learning, and designing assessments aligned with learning objectives. 
            </p>
            <p> For those interested, I will be uploading the syllabus I created for the class here. I used Professor Gang Chen's syllabus for his <a href="https://ocw.mit.edu/courses/2-58j-radiative-transfer-spring-2006/" target="_blank">Radiative Transfer</a> class as a starting point, and then modified it to fit my own teaching style and philosophy.
            </p>
            <p> Thanks to the Teaching + Learning Lab for putting on this certificate, and to the instructor Nathalie Vladis for her insightful classes. I'm excited to continue to develop my teaching skills and philosophy in the future! </p>

        
      </div>
    );
  }
}
export default KTCP_241212;
