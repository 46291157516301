import React, { Component } from "react";
import { Divider } from 'antd';
import "./App.scss";

class PublicationsPage extends Component {

  public render() {
    return (
      <div className="Page">
        <h1>Publications</h1>
        <h3>&nbsp;&nbsp;&nbsp;Lead author</h3>
        <ol reversed>
        <li className="pubList">
            <h2 className="publicationTitle">
            High-temperature blackbody emitters for enhanced radiative energy transport [<a href="https://www.spiedigitallibrary.org/conference-proceedings-of-spie/PC12873/PC128730K/Ultrafast-femtosecond-laser-processing-for-broadband-emitters/10.1117/12.2692946.full" target="_blank">Preview</a>]
            </h2>
            <p className="authorsText"> Minok Park*, <span className="selfAuthor">Shomik Verma</span>*, Alina LaPotin, Dustin P. Nizamian, Ravi Prasher,
Asegun Henry, Sean D. Lubner, Costas P. Grigoropoulos, and Vassilia Zorba</p>
            <p className="journalText"> <span className="journalName">Coming Soon!</span></p>
          </li>
          <Divider />
        <li className="pubList">
            <h2 className="publicationTitle">
            <a href="https://sciencepolicyreview.pubpub.org/pub/3v3e6ed7" target="_blank"> Decarbonizing industry: policy approaches to eliminate hard-to-abate emissions </a>[<a href="pdfs/240828_MITSPR.pdf" target="_blank">PDF</a>]
            </h2>
            <p className="authorsText"> Kyle Buznitsky*, <span className="selfAuthor">Shomik Verma</span>*, Michael Nitzsche</p>
            <p className="journalText"> <span className="journalName">MIT SPR</span>, August 2024.</p>
          </li>
          <Divider />
        <li className="pubList">
            <h2 className="publicationTitle">
            <a href="https://arxiv.org/abs/2407.00940" target="_blank"> Thermophotovoltaic performance metrics and technoeconomics: efficiency vs. power density</a> [<a href="pdfs/240929_TPVTEA_paper.pdf" target="_blank">PDF</a>]
            </h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">arXiv</span>, July 2024.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Data and code on GitHub: <a href="https://github.com/shomikverma/TPV_TEA" target="_blank">shomikverma/TPV_TEA</a></p>
              <p> Webapp: <a href="https://sites.mit.edu/tpvsim" target="_blank">sites.mit.edu/tpvsim</a></p>
            </div>
          </li>
          <Divider />
        <li className="pubList">
            <h2 className="publicationTitle">
            <a href="https://arxiv.org/abs/2402.07764" target="_blank"> Designing for effective heat transfer in a solid thermal energy storage system </a>[<a href="pdfs/240212_graphite_discharge_paper_incl_SI.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Colin Kelsall, Alina LaPotin, Ashwin Sandeep, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">arXiv</span>, February 2024.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Data and code on GitHub: <a href="https://github.com/shomikverma/graphite-discharge" target="_blank">shomikverma/graphite-discharge</a></p>
            </div>
          </li>
          <Divider />
          
          <li className="pubList">
            <h2 className="publicationTitle">
            <a href="https://www.ihtcdigitallibrary.com/conferences/ihtc17,5123fe614f4c58e0,6b12a23649e8b58c.html" target="_blank"> High-temperature thermal conductivity measurements of macro-porous graphite </a> [<a href="pdfs/230519_IHTC_paper.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Michael Adams, Mary Foxen, Bryan Sperry, Shannon Yee, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">International Heat Transfer Conference 17</span>, August 2023.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Data and code on GitHub: <a href="https://github.com/shomikverma/graphite-modeling">shomikverma/graphite-modeling</a></p>
            </div>
          </li>
          <Divider />
          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="https://pubs.acs.org/doi/10.1021/acsaom.3c00074" target="_blank"> Ray trace modeling to characterize efficiency of unconventional luminescent solar concentrator geometries </a> [<a href="pdfs/230511_pvtrace_paper.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Daniel Farrell, Rachel Evans</p>
            <p className="journalText"> <span className="journalName">ACS Applied Optical Materials</span>, May 2023.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Code on GitHub: <a href="https://github.com/shomikverma/pvtrace-sv">shomikverma/pvtrace-sv</a></p>
            </div>
          </li>
          <Divider />
          
          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="https://aip.scitation.org/doi/10.1063/5.0084535" target="_blank"> Machine learned calibrations to high-throughput molecular excited state calculations</a> [<a href="pdfs/2022_xTB_ML_paper.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Miguel Rivera, David O. Scanlon, Aron Walsh</p>
            <p className="journalText"> <span className="journalName">Journal of Chemical Physics</span>, April 2022.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Data on GitHub: <a href="https://github.com/shomikverma/xTB-ML-data">shomikverma/xTB-ML-data</a></p>
              <p>Code on Github: <a href="https://github.com/shomikverma/xTB-ML-workflow">shomikverma/xTB-ML-workflow</a></p>
            </div>
          </li>
          <Divider />
          
          <li className="pubList">
            <h2 className="publicationTitle"><a href="https://www.comsol.com/paper/modeling-a-concentrated-solar-thermal-collector-for-methane-dry-reforming-64342" target="_blank"> Modeling a Concentrated Solar Thermal Collector for Methane Dry Reforming</a> [<a href="pdfs/COMSOL2018_paper.pdf" target="_blank">PDF</a>] [<a href="pdfs/COMSOL2018_poster.pdf" target="_blank">Poster</a>]</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span> and Nico Hotz.</p>
            <p className="journalText"> <span className="journalName">COMSOL Conference</span>, October 2018.</p>
            <h4>News</h4>
            <p><a href="https://www.comsol.com/blogs/award-winning-papers-and-posters-comsol-conference-2018-boston/" target="_blank">Award-Winning Papers and Posters: COMSOL Conference 2018 Boston</a>, COMSOL Blog.</p>
          </li>
        </ol>
        <p>&nbsp;&nbsp;&nbsp;* denotes equal contribution</p>
        <br></br>

        <h3>&nbsp;&nbsp;&nbsp;Co-author</h3>
        <ol reversed>
        <li className="pubList">
            <h2 className="publicationTitle">
              <a href="https://www.sciencedirect.com/science/article/pii/S0306261923009364" target="_blank"> Power availability of PV plus thermal batteries in real-world electric power grids </a> [<a href="pdfs/230727_odin_TEGS_paper_1.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> Odin Foldvik Eikeland, Colin C. Kelsall, Kyle Buznitsky, <span className="selfAuthor">Shomik Verma</span>, Filippo Maria Bianchi, Matteo Chiesa, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">Applied Energy</span>, October 2023.</p>
          </li>
          <Divider />
          
          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="https://www.nature.com/articles/s41586-022-04473-y" target="_blank"> Thermophotovoltaic efficiency of 40% </a> [<a href="pdfs/2022_TPV_paper.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> Alina LaPotin, Kevin L. Schulte, Myles A. Steiner, Kyle Buznitsky, Colin C. Kelsall, Daniel J. Friedman, Eric J. Tervo, Ryan M. France, Michelle R. Young, Andrew Rohskopf, <span className="selfAuthor">Shomik Verma</span>, Evelyn N. Wang, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">Nature</span>, April 2022.</p>
            <div className="suppInfo">
              <h4>News</h4>
              <p><a href="https://news.mit.edu/2022/thermal-heat-engine-0413" target="_blank">A new heat engine with no moving parts is as efficient as a steam turbine</a>, MIT News.</p>
            </div>
          </li>
          <Divider />

          <li className="pubList">
            <h2 className="publicationTitle">
              <a href="https://ieeexplore.ieee.org/document/8952212" target="_blank"> A Study of Energy Losses in the World's Most Fuel-Efficient Vehicle</a> [<a href="pdfs/VPPC2019_paper.pdf" target="_blank">PDF</a>]</h2>
            <p className="authorsText"> Patrick Grady, Gerry Chen, <span className="selfAuthor">Shomik Verma</span>, Aniruddh Marellapudi, Nico Hotz</p>
            <p className="journalText"> <span className="journalName">IEEE Vehicle Power and Propulsion Coneference</span>, October 2019.</p>
            <div className="suppInfo">
              <h4>Supporting Information</h4>
              <p>Code on Github: <a href="https://github.com/DukeElectricVehicles/EnergyLossSim">DukeElectricVehicles/EnergyLossSim</a></p>
              <br></br>
              <h4>News</h4>
              <p><a href="https://www.dukechronicle.com/article/2018/08/the-mountain-no-one-thought-they-could-climb-duke-team-sets-world-record-for-most-efficient-vehicle" target="_blank">Duke team sets world record for most efficient vehicle</a>, Duke Chronicle.</p>
              <p><a href="https://www.youtube.com/watch?v=OkNlXpRie2M" target="_blank">Duke Electric Vehicles Team Breaks Guinness World Record for Fuel Efficiency</a>, Duke Energy Initiative (video).</p>
              <p><a href="https://www.guinnessworldrecords.com/products/books" target="_blank"> Most Fuel-Efficient Vehicle (Prototype)</a>  Guinness World Records 2020 (Page 173).</p>
            </div>
          </li>
          <Divider />

        </ol>
        <h1>Perspectives</h1>
        <ol reversed >
        <li className="pubList"> <span className="general">
        <h2 className="publicationTitle">
              <a href="https://yaleclimateconnections.org/2024/09/recycling-isnt-the-solution-to-the-climate-crisis/" target="_blank">Recycling isn’t the solution to the climate crisis</a> </h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span></p>
            <p className="journalText"> <span className="journalName">Yale Climate Connections</span>, September 2024.</p>
              </span>
              </li>
        <li className="pubList"> <span className="general">
        <h2 className="publicationTitle">
              <a href="https://www.fairobserver.com/more/environment/we-cant-ignore-the-human-toll-of-climate-change/" target="_blank">We Can't Ignore the Human Toll of Climate Change</a> </h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span></p>
            <p className="journalText"> <span className="journalName">Fair Observer</span>, September 2024.</p>
              </span>
              </li>
        <li className="pubList"> <span className="general">
        <h2 className="publicationTitle">
              <a href="https://thehill.com/opinion/energy-environment/4844115-vermont-climate-superfund-emissions/" target="_blank">We need to make polluters pay — but not consumers</a> </h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span></p>
            <p className="journalText"> <span className="journalName">The Hill</span>, August 2024.</p>
              </span>
              </li>
        </ol>
        <br></br>

        <h1>Posters and Presentations</h1>
        <ol reversed >

        <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [Best Student Presentation] <a href="pdfs/241003_Verma_TPV15.pdf" target="_blank">Enhancement of TPV power density with surface-engineered emitters</a> </h2>
              <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Minok Park, Sean Lubner, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">TPV-15 Conference</span>, October 2024.</p>
          </span>
          </li>

        <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [Best Paper Award] <a href="pdfs/240829_SVerma_MITAB_Presentation.pdf" target="_blank">Thermophotovoltaic systems can provide cost-effective, dispatchable clean power</a> (<a href="https://www.youtube.com/watch?v=uUN0qajxCsw" target="_blank">Video</a>)</h2>
              <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Mehdi Pishahang, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">MIT A+B Applied Energy Symposium</span>, August 2024.</p>
          </span>
          </li>

        <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/240714_SVerma_SHTC_Presentation.pdf" target="_blank">Designing a high-efficiency, low-NOx hydrogen combustor for thermophotovoltaic power generation</a> </h2>
              <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Mehdi Pishahang, Ahmed Ghoniem, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">ASME Summer Heat Transfer Conference</span>, July 2024.</p>
          </span>
          </li>
          
        <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [Best Poster Award Finalist] <a href="pdfs/231122_FallMRS_Poster.pdf" target="_blank">Additively-manufactured ceramic combustor for dispatchable clean electricity generation</a> </h2>
              <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Nicholas Orf, Brian LaCourse, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">Materials Research Society (MRS) Fall Meeting</span>, November 2023.</p>
            {/* <h4>News</h4>
            <p>Best Poster Award Finalist</p> */}
          </span>
          </li>

        <li className="pubList"> <span className="general">
          <h2 className="publicationTitle">
            <a href="pdfs/231201_FallMRS_LSC_Presentation.pdf" target="_blank"> End-to-end performance analysis of 3D printed luminescent devices for energy conversion applications</a> </h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Rachel Evans</p>
          <p className="journalText"> <span className="journalName">Materials Research Society (MRS) Fall Meeting</span>, November 2023.</p>
        </span>
        </li>

        <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/230807_IHTC_Poster.pdf" target="_blank">High-temperature thermal conductivity measurements </a><a href="pdfs/230807_IHTC_Presentation.pdf" target="_blank">of macro-porous graphite</a> </h2>
              <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Michael Adams, Mary Foxen, Bryan Sperry, Shannon Yee, Asegun Henry</p>
            <p className="journalText"> <span className="journalName">International Heat Transfer Conference 17</span>, August 2023.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [Student Presentation Award Honorable Mention] <a href="pdfs/230526_TPV14_Presentation.pdf" target="_blank">Design of an all-ceramic hydrogen combustor paired with thermophotovoltaic power generation</a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Mehdi Pishahang, Kyle Buznitsky, Alina LaPotin, Santosh Shanbogue, Asegun Henry.</p>
            <p className="journalText"> <span className="journalName">TPV-14 Conference</span>, May 2023.</p>
            {/* <h4>News</h4>
            <p>Student Presentation Award: Honorable Mention</p> */}
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/230103_HotP_Poster.pdf" target="_blank"> Improving the performance of cheap thermal energy grid storage </a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Colin Kelsall, Alina LaPotin, Michael Adams, Mary Foxen, Bryan Sperry, Shannon Yee, Asegun Henry.</p>
            <p className="journalText"> <span className="journalName">MIT GSC Poster Expo</span>, April 2023.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [Best Student Presentation] <a href="pdfs/230315_PITCHES_SVerma.pdf" target="_blank"> Clean, dispatchable power with hydrogen combustion and thermophotovoltaics </a> (<a href="https://www.youtube.com/watch?v=lXCBzZ__x1c" target="__blank">Video</a>)</h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Kyle Buznitsky, Alina LaPotin, Mehdi Pishahang, Santosh Shanbogue, Asegun Henry.</p>
            <p className="journalText"> <span className="journalName">ARPA-E Summit</span>, March 2023.</p>
            <div className="suppInfo">
              {/* <h4>News</h4> */}
              <p> </p>
            </div>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/221127_Graphite_MRS_Presentation.pdf" target="_blank"> Analyzing the performance of low-quality graphite for high-temperature thermal storage </a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Colin Kelsall, Michael Adams, Kyle Buznitsky, Alina LaPotin, Shannon Yee, Asegun Henry.</p>
            <p className="journalText"> <span className="journalName">Materials Research Society (MRS) Fall Meeting</span>, November 2022.</p>
          </span>
          </li>
          
          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/221127_AL_MRS_Presentation.pdf" target="_blank"> A unified active learning framework for designing energy-relevant molecules </a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Jiali Li, Kevin Greenman, Rafael Gomez-Bombarelli, Xiaonan Wang, Aron Walsh.</p>
            <p className="journalText"> <span className="journalName">Materials Research Society (MRS) Fall Meeting</span>, November 2022.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="https://ieeexplore.ieee.org/abstract/document/9926355" target="_blank"> Machine learning for accurate and fast bandgap prediction of solid-state materials </a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Shivam Kajale, Rafael Gomez-Bombarelli.</p>
            <p className="journalText"> <span className="journalName">IEEE High Performance Extreme Computing Conference (HPEC)</span>, September 2022.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              [3rd Place Poster Award] <a href="pdfs/EW2018_SVerma_poster.pdf" target="_blank"> Finite element modeling of a concentrated solar collector for hydrogen production</a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span> and Nico Hotz.</p>
            <p className="journalText"> <span className="journalName">Duke University Energy Conference</span>, November 2018.</p>
            {/* <h4>News</h4>
            <p> 3rd Place Poster Award </p> */}
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/EW2018_DEV_poster.pdf" target="_blank"> Design and optimization of the hydrogen fuel cell drivetrain of the world’s most fuel-efficient vehicle</a></h2>
            <p className="authorsText"> Gerry Chen, <span className="selfAuthor">Shomik Verma</span>, Josiah Knight, and Nico Hotz.</p>
            <p className="journalText"> <span className="journalName">Duke University Energy Conference</span>, November 2018.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/NCEnergy_poster.pdf" target="_blank"> Optimizing and fabricating a high-temperature selective absorption coating for a concentrated solar thermal collector</a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span>, Qingyu Xiao, and Nico Hotz.</p>
            <p className="journalText"> <span className="journalName">North Carolina Energy Conference</span>, April 2018.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/EW2017_SVerma_poster.pdf" target="_blank"> Developing a numerical heat transfer model for a high-temperature concentrated solar thermal collector</a></h2>
            <p className="authorsText"> <span className="selfAuthor">Shomik Verma</span> and Nico Hotz.</p>
            <p className="journalText"> <span className="journalName">Duke University Energy Conference</span>, November 2017.</p>
          </span>
          </li>

          <li className="pubList"> <span className="general">
            <h2 className="publicationTitle">
              <a href="pdfs/EW2017_DEV_poster.pdf" target="_blank"> Optimizing the operational efficiency of a PEM hydrogen fuel cell for applications in a hybrid electric vehicle</a></h2>
            <p className="authorsText"> Gerry Chen, <span className="selfAuthor">Shomik Verma</span>, Patrick Grady, Nico Hotz, and Josiah Knight.</p>
            <p className="journalText"> <span className="journalName">Duke University Energy Conference</span>, November 2017.</p>
          </span>
          </li>

        </ol>

      </div>

    );
  }
}

export default PublicationsPage;
